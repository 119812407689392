import Vue from 'vue'

import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'

/* Plugins */

import nuxt_plugin_plugin_30fa95df from 'nuxt_plugin_plugin_30fa95df' // Source: ./composition-api/plugin.mjs (mode: 'all')
import nuxt_plugin_wmIcons_0eacd89f from 'nuxt_plugin_wmIcons_0eacd89f' // Source: ./wmIcons.js (mode: 'all')
import nuxt_plugin_nuxtplugin9ed440fc_2a789606 from 'nuxt_plugin_nuxtplugin9ed440fc_2a789606' // Source: ./nuxt.plugin.9ed440fc.js (mode: 'server')
import nuxt_plugin_image_106b3670 from 'nuxt_plugin_image_106b3670' // Source: ./image.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_e7d46516 from 'nuxt_plugin_cookieuniversalnuxt_e7d46516' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_pluginutils_002ffa0e from 'nuxt_plugin_pluginutils_002ffa0e' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginseo_1a2318e2 from 'nuxt_plugin_pluginseo_1a2318e2' // Source: ./nuxt-i18n/plugin.seo.js (mode: 'all')
import nuxt_plugin_pluginrouting_6a1a3bd9 from 'nuxt_plugin_pluginrouting_6a1a3bd9' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_34623394 from 'nuxt_plugin_pluginmain_34623394' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_runtimeplugin92890384_19c1185a from 'nuxt_plugin_runtimeplugin92890384_19c1185a' // Source: ./runtime.plugin.92890384.mjs (mode: 'all')
import nuxt_plugin_customerplugin_f692b240 from 'nuxt_plugin_customerplugin_f692b240' // Source: ./customer.plugin.js (mode: 'all')
import nuxt_plugin_runtimeplugin1e57b379_6e2a8e2c from 'nuxt_plugin_runtimeplugin1e57b379_6e2a8e2c' // Source: ./runtime.plugin.1e57b379.mjs (mode: 'all')
import nuxt_plugin_runtimepluginfe9f0e3c_70e16312 from 'nuxt_plugin_runtimepluginfe9f0e3c_70e16312' // Source: ./runtime.plugin.fe9f0e3c.mjs (mode: 'all')
import nuxt_plugin_runtimeplugin46542695_163db47c from 'nuxt_plugin_runtimeplugin46542695_163db47c' // Source: ./runtime.plugin.46542695.mjs (mode: 'all')
import nuxt_plugin_runtimeplugin6f775589_d472169c from 'nuxt_plugin_runtimeplugin6f775589_d472169c' // Source: ./runtime.plugin.6f775589.mjs (mode: 'all')
import nuxt_plugin_distplugin70c9f803_5b6484ea from 'nuxt_plugin_distplugin70c9f803_5b6484ea' // Source: ./dist.plugin.70c9f803.mjs (mode: 'all')
import nuxt_plugin_pluginsfc29b2151e_b9f44764 from 'nuxt_plugin_pluginsfc29b2151e_b9f44764' // Source: ./plugins.fc.29b2151e.mjs (mode: 'all')
import nuxt_plugin_coreplugina727dfc0_45fbc72a from 'nuxt_plugin_coreplugina727dfc0_45fbc72a' // Source: ./core.plugin.a727dfc0.ts (mode: 'all')
import nuxt_plugin_pluginstokenexpiredc4c2e1f6_1eb611cc from 'nuxt_plugin_pluginstokenexpiredc4c2e1f6_1eb611cc' // Source: ./plugins.token-expired.c4c2e1f6.ts (mode: 'all')
import nuxt_plugin_pluginsdompurify0304d376_6cb528be from 'nuxt_plugin_pluginsdompurify0304d376_6cb528be' // Source: ./plugins.dompurify.0304d376.ts (mode: 'all')
import nuxt_plugin_pluginsi18n2ee15d34_4a7f6f88 from 'nuxt_plugin_pluginsi18n2ee15d34_4a7f6f88' // Source: ./plugins.i18n.2ee15d34.ts (mode: 'all')
import nuxt_plugin_pluginsfixLocaleRoutes3603ec1d_f322f532 from 'nuxt_plugin_pluginsfixLocaleRoutes3603ec1d_f322f532' // Source: ./plugins.fixLocaleRoutes.3603ec1d.ts (mode: 'all')
import nuxt_plugin_pluginse2etestinga31e7a00_6850603a from 'nuxt_plugin_pluginse2etestinga31e7a00_6850603a' // Source: ./plugins.e2e-testing.a31e7a00.js (mode: 'all')
import nuxt_plugin_deviceplugin_1c5e3b50 from 'nuxt_plugin_deviceplugin_1c5e3b50' // Source: ./device.plugin.js (mode: 'all')
import nuxt_plugin_workbox_6b591da9 from 'nuxt_plugin_workbox_6b591da9' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_6572abbc from 'nuxt_plugin_metaplugin_6572abbc' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_iconplugin_6a1a5130 from 'nuxt_plugin_iconplugin_6a1a5130' // Source: ./pwa/icon.plugin.js (mode: 'all')
import nuxt_plugin_storeConfigPlugin_cbd09bc6 from 'nuxt_plugin_storeConfigPlugin_cbd09bc6' // Source: ../node_modules/@wemade-vsf/core/lib/runtime/plugins/storeConfigPlugin.mjs (mode: 'server')
import nuxt_plugin_meta_6c07469e from 'nuxt_plugin_meta_6c07469e' // Source: ./composition-api/meta.mjs (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp(ssrContext, config = {}) {
  const store = null
  const router = await createRouter(ssrContext, config, { store })

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Vestfold","titleTemplate":"%s - Vestfold","meta":[{"charset":"utf8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":""},{"hid":"og:site_name","property":"og:site_name","name":"og:site_name","content":"Vestfold"},{"hid":"og:title","name":"og:title","property":"og:title","content":"","template":(chunk) => chunk ? `${chunk} - Vestfold` : 'Vestfold'},{"hid":"og:description","property":"og:description","content":""},{"hid":"apple-mobile-web-app-title","property":"apple-mobile-web-app-title","content":"Vestfold"},{"name":"og:image","hid":"og:image","property":"og:image","content":"\u002Fogimage.jpg"},{"name":"generator","content":"Vue Storefront 2"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"}],"bodyAttrs":{"id":"html-body"},"style":[],"script":[]},

    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_30fa95df === 'function') {
    await nuxt_plugin_plugin_30fa95df(app.context, inject)
  }

  if (typeof nuxt_plugin_wmIcons_0eacd89f === 'function') {
    await nuxt_plugin_wmIcons_0eacd89f(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_nuxtplugin9ed440fc_2a789606 === 'function') {
    await nuxt_plugin_nuxtplugin9ed440fc_2a789606(app.context, inject)
  }

  if (typeof nuxt_plugin_image_106b3670 === 'function') {
    await nuxt_plugin_image_106b3670(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_e7d46516 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_e7d46516(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_002ffa0e === 'function') {
    await nuxt_plugin_pluginutils_002ffa0e(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginseo_1a2318e2 === 'function') {
    await nuxt_plugin_pluginseo_1a2318e2(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_6a1a3bd9 === 'function') {
    await nuxt_plugin_pluginrouting_6a1a3bd9(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_34623394 === 'function') {
    await nuxt_plugin_pluginmain_34623394(app.context, inject)
  }

  if (typeof nuxt_plugin_runtimeplugin92890384_19c1185a === 'function') {
    await nuxt_plugin_runtimeplugin92890384_19c1185a(app.context, inject)
  }

  if (typeof nuxt_plugin_customerplugin_f692b240 === 'function') {
    await nuxt_plugin_customerplugin_f692b240(app.context, inject)
  }

  if (typeof nuxt_plugin_runtimeplugin1e57b379_6e2a8e2c === 'function') {
    await nuxt_plugin_runtimeplugin1e57b379_6e2a8e2c(app.context, inject)
  }

  if (typeof nuxt_plugin_runtimepluginfe9f0e3c_70e16312 === 'function') {
    await nuxt_plugin_runtimepluginfe9f0e3c_70e16312(app.context, inject)
  }

  if (typeof nuxt_plugin_runtimeplugin46542695_163db47c === 'function') {
    await nuxt_plugin_runtimeplugin46542695_163db47c(app.context, inject)
  }

  if (typeof nuxt_plugin_runtimeplugin6f775589_d472169c === 'function') {
    await nuxt_plugin_runtimeplugin6f775589_d472169c(app.context, inject)
  }

  if (typeof nuxt_plugin_distplugin70c9f803_5b6484ea === 'function') {
    await nuxt_plugin_distplugin70c9f803_5b6484ea(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsfc29b2151e_b9f44764 === 'function') {
    await nuxt_plugin_pluginsfc29b2151e_b9f44764(app.context, inject)
  }

  if (typeof nuxt_plugin_coreplugina727dfc0_45fbc72a === 'function') {
    await nuxt_plugin_coreplugina727dfc0_45fbc72a(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginstokenexpiredc4c2e1f6_1eb611cc === 'function') {
    await nuxt_plugin_pluginstokenexpiredc4c2e1f6_1eb611cc(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsdompurify0304d376_6cb528be === 'function') {
    await nuxt_plugin_pluginsdompurify0304d376_6cb528be(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsi18n2ee15d34_4a7f6f88 === 'function') {
    await nuxt_plugin_pluginsi18n2ee15d34_4a7f6f88(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsfixLocaleRoutes3603ec1d_f322f532 === 'function') {
    await nuxt_plugin_pluginsfixLocaleRoutes3603ec1d_f322f532(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginse2etestinga31e7a00_6850603a === 'function') {
    await nuxt_plugin_pluginse2etestinga31e7a00_6850603a(app.context, inject)
  }

  if (typeof nuxt_plugin_deviceplugin_1c5e3b50 === 'function') {
    await nuxt_plugin_deviceplugin_1c5e3b50(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_6b591da9 === 'function') {
    await nuxt_plugin_workbox_6b591da9(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_6572abbc === 'function') {
    await nuxt_plugin_metaplugin_6572abbc(app.context, inject)
  }

  if (typeof nuxt_plugin_iconplugin_6a1a5130 === 'function') {
    await nuxt_plugin_iconplugin_6a1a5130(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_storeConfigPlugin_cbd09bc6 === 'function') {
    await nuxt_plugin_storeConfigPlugin_cbd09bc6(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_6c07469e === 'function') {
    await nuxt_plugin_meta_6c07469e(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    app,
    router
  }
}

export { createApp, NuxtError }
