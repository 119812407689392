













import {
  defineComponent
} from '@nuxtjs/composition-api'
import { useConfig } from '@wemade-vsf/composables';

export default defineComponent({
  name: 'TopNavigation',
  props: {
    accountIcon: {
      type: Boolean,
      default: true
    }
  },
  components: {
    CmsBlocks: () => import('components/theme/Content/CmsBlocks.vue'),
    AccountIcon: () => import('components/theme/Icons/AccountIcon.vue')
  },
  setup () {
    const { config } = useConfig();

    const blockIdentifiers = config.value?.storefront?.top_menu_block_identifier ?? null;

    return {
      blockIdentifiers
    }
  }
})
